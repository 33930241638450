export const colorsList = [
  { base: '#AD1457', contrast: '#FFFFFF' },
  { base: '#6A1B9A', contrast: '#FFFFFF' },
  { base: '#4527A0', contrast: '#FFFFFF' },
  { base: '#283593', contrast: '#FFFFFF' },
  { base: '#1565C0', contrast: '#FFFFFF' },
  { base: '#0277BD', contrast: '#FFFFFF' },
  { base: '#00838F', contrast: '#FFFFFF' },
  { base: '#00695C', contrast: '#FFFFFF' },
  { base: '#2E7D32', contrast: '#FFFFFF' },
  { base: '#827717', contrast: '#FFFFFF' },
  { base: '#E65100', contrast: '#FFFFFF' },
  { base: '#BF360C', contrast: '#FFFFFF' },
  { base: '#4E342E', contrast: '#FFFFFF' },
  { base: '#424242', contrast: '#FFFFFF' },
  { base: '#263238', contrast: '#FFFFFF' },
]
