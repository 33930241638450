import { BrowserSize } from 'components/browser/Browser'
import Block from 'components/planner/mapper/blocks/Block'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { nanoid } from 'nanoid'
import React, { useContext, useEffect, useState } from 'react'
import { isPass } from 'utils/data/grades'
import checkMap from '../../check-map'
import c from './handbook.module.scss'

const HandbookBlocks = ({ data, double = false }) => {
  // list

  const { courseMap, flatCourseMap, eligibleAoS } = useContext(Data)
  const [renderList, setRenderList] = useState([])

  useEffect(() => {
    setRenderList(
      data
        .map((item, i) => {
          let onMap = checkMap(item.name, 'name', flatCourseMap)

          if (onMap) {
            if (onMap.origin === 'CREDIT') {
              item.status = onMap.grantingStatus
            } else {
              item.status = onMap.status
            }

            item.grade = onMap.grade
          }
          const notUnit = item.blockType !== 'UNIT'

          if (notUnit && eligibleAoS[item.name] && courseMap) {
            item.eligible = eligibleAoS[item.name].units
            onMap = courseMap.aos?.some((aos) => aos.code === item.name)
          }

          if (notUnit && courseMap) {
            onMap = checkMap(item.name, 'code', courseMap.aos)
          }

          if (onMap) {
            if (!notUnit) {
              item.id = nanoid()
              //const grade = gradeStatus(onMap.grade)
              item.status =
                onMap.status && !['DISCONTIN'].includes(onMap.status)
                  ? onMap.status
                  : null
              item.grade = onMap.grade
                // onMap.grade &&
                // !['FAIL', 'WITHDRAWN'].includes(grade)
                //   ? onMap.grade
                //   : null
              if (onMap.blockType === 'CREDIT') {
                item.category = onMap.category || null
                item.grantingStatus = onMap.grantingStatus || null
              }
            }

            if (notUnit) {
              item.status = 'NOMINATED'
            }
          }

          if (!onMap) {
            item.status = null
          }

          return { item, onMap }
        })
        .map((data, i) => (
          <Block 
            block={data.item} 
            key={i} 
            disabled={
              (data.onMap &&
                (isPass(data.onMap.grade) || !data.onMap.grade)
              )
              || data.item.type === 'COURSE'
            } 
          />
        ))
    )
  }, [courseMap, courseMap?.aos, data, eligibleAoS, flatCourseMap])

  // size

  const { browserSize } = useContext(BrowserSize)
  const [columns, setcolumns] = useState(4)

  useEffect(() => {
    let width = double ? browserSize/2 : browserSize
    if (width > 900) setcolumns(4)
    if (width < 900 && width > 750) setcolumns(3)
    if (width < 750 && width > 450) setcolumns(2)
    if (width < 450) setcolumns(1)
  }, [browserSize, double])

  const style = {
    grid: `auto-flow / repeat(${columns}, 1fr)`,
  }

  // render

  return (
    <div className={c.HandbookBlocks} style={style}>
      {renderList}
    </div>
  )
}

export default HandbookBlocks
