import React, { useEffect, useState, useContext } from 'react'
import c from './glossary.module.scss'
import glossaryData from './glossary-data.json'
import GlossaryItem from './glossary-item/GlossaryItem'
import { BrowserSize } from 'components/browser/Browser'

const alphabetList = [...'abcdefghijklmnopqrstuvwxyz']

const filterItemsByAlphabet = (letter) => {
  return glossaryData.filter(
    (item) => item.name.toLowerCase().charAt(0) === letter
  )
}

const Glossary = () => {
  const { browserSize } = useContext(BrowserSize)
  const [columns, setcolumns] = useState(3)

  useEffect(() => {
    if (browserSize > 1200) setcolumns(3)
    if (browserSize < 1200 && browserSize > 900) setcolumns(2)
    if (browserSize < 900) setcolumns(1)
  }, [browserSize])

  const itemStyle = {
    grid: `auto-flow / repeat(${columns}, 1fr)`,
  }

  const [activeItems] = useState('All')
  // const ref = useRef()

  return (
    <div className={c.glossary}>
      <header>
        <h1>Glossary</h1>
      </header>
      {/* <Tabs onChange={} tabs={} activeKey={}></Tabs> */}
      {activeItems === 'All'
        ? alphabetList.map((alphabet, i) => {
            const items = filterItemsByAlphabet(alphabet)
            return Boolean(items.length) ? (
              <div key={i} className={c.glossaryGroup}>
                <h1>{alphabet.toUpperCase()}</h1>
                <div className={c.glossaryItems} style={itemStyle}>
                  {filterItemsByAlphabet(alphabet).map((item, i) => (
                    <GlossaryItem
                      key={i}
                      name={item.name}
                      definition={item.definition}
                    />
                  ))}
                </div>
              </div>
            ) : (
              ''
            )
          })
        : ''}
    </div>
  )
}

export default Glossary
