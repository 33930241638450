import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import c from './progression-map.module.scss'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import checkMap from '../../check-map'
import PMapTable from './PMapTable'
import { nanoid } from 'nanoid'
import Icon from 'components/utilities/icons/Icon'
import { gradeStatus } from 'utils/data/grades'
import { fs } from 'utils/firebase'
import { capitalise } from 'utils/transforms/string-transforms'
import LoadingScreen from 'components/utilities/loading-indicator/LoadingScreen'
import { getProgressionMaps } from 'utils/services'
import { years, defaultYear } from 'utils/data/years'

// Data transform to block format
const blockify = (code, description, label, creditPoints, y, tp, i) => {
  const newBlock = {
    id: nanoid(),
    type: 'BLOCK',
    blockType: 'UNIT',
    origin: 'PMAP',
    name: code || null,
    elective: !code,
    electiveId: null,
    description,
    courseLabel: label,
    year: y,
    periodIndex: tp,
    index: i,
    creditPoints: creditPoints,
  }

  return newBlock
}

//
// start
//

const ProgressionMap = ({ pageData }) => {
  const {
    courseMap,
    setCourseMap,
    autosave,
    flatCourseMap,
    selectedPMap,
    setSelectedPMap,
  } = useContext(Data)

  const filteredFlatMap = flatCourseMap.filter((item) => {
    const grade = gradeStatus(item.grade)
    if (['DISCONTIN'].includes(item.status)) return false
    if (['FAIL', 'WITHDRAWN'].includes(grade)) return false
    return true
  })

  //Get all course progression maps base on the year and course
  const [studentEnrolledYear] = useState(
    courseMap ? courseMap.startingYear : null
  )
  const [year, setYear] = useState(
    studentEnrolledYear
      ? parseInt(studentEnrolledYear) >= 2016
        ? studentEnrolledYear
        : defaultYear
      : defaultYear
  )

  const [course] = useState(
    //courses.find((item) => item === selectedPlan.code)
    pageData?.code
  )

  const [maps, setMaps] = useState([])
  const [filters, setFilters] = useState(null)
  const [urls, setUrls] = useState(null)
  const [loading, setLoading] = useState(true)
  const [blockBulkAdd, setBlockBulkAdd] = useState(false)
  const [bulkCount, setBulkCount] = useState({ unit: 0, elective: 0 })

  const required = useMemo(() => ['code', 'year'], [])

  const addCache = (code, year, maps) => {
    let cache = JSON.parse(sessionStorage.getItem('mapCache')) || {}
    cache[`${code}-${year}`] = maps
    sessionStorage.setItem('mapCache', JSON.stringify(cache))
  }

  const getFilters = useCallback(
    (maps) => {
      let filterList = {} //{key: [values]}
      maps.forEach((doc) => {
        doc.filter_fields.forEach((f) => {
          if (!required.includes(f)) {
            if (filterList[f]) {
              if (!filterList[f].values.includes(doc[f])) {
                filterList[f].values.push(doc[f])
              }
            } else {
              filterList[f] = {
                key: f,
                values: [doc[f]],
                selected: doc[f],
              }
            }
          }
        })
      })
      setFilters(filterList)
    },
    [required]
  )

  //filter maps onyear and course code
  useEffect(() => {
    if (year && course) {
      setLoading(true)

      //check cache
      let cache = JSON.parse(sessionStorage.getItem('mapCache'))
      if (cache?.[`${course}-${year}`]) {
        setMaps(cache[`${course}-${year}`])
        getFilters(cache[`${course}-${year}`])
        setLoading(false)
      } else {
        //no in cache get fresh maps
        getProgressionMaps({
          filters: [
            { key: 'code', value: course },
            { key: 'year', value: year },
          ],
        }).then((docs) => {
          //add to cache
          addCache(course, year, docs)

          setMaps(docs)
          getFilters(docs)
          setLoading(false)
        })
      }
    }
  }, [year, course, required, getFilters])

  const getMap = useCallback(() => {
    if (filters) {
      let filtered = maps.filter((map) => {
        return !map.filter_fields
          .filter((f) => !required.includes(f))
          .some((f) => {
            return filters[f]?.selected !== map[f]
          })
      })
      let chosenOne = filtered[0]
      if (chosenOne) {
        chosenOne.map?.forEach((year, y) => {
          year.periods.forEach((period, tp) => {
            period.units.forEach((unit, u) => {
              if (chosenOne.map[y].periods[tp].units[u].type !== 'BLOCK') {
                chosenOne.map[y].periods[tp].units[u] = blockify(
                  unit.unit_code || unit.name,
                  unit.description,
                  unit.label || null,
                  unit.creditPoints || null,
                  y,
                  tp,
                  u
                )
              }
            })
          })
        })
        return chosenOne
      } else {
        return {}
      }
    } else {
      return {}
    }
  }, [filters, maps, required])

  useEffect(() => {
    const selected = getMap()
    //check for stored maps
    let existing = courseMap ? courseMap.pMaps?.[course]?.[year] : null
    //if existing maps, check if they match currently selected filters
    existing = Array.isArray(existing)
      ? existing.filter((p) => {
          //isEqual(p.filters, selected.filters)
          if (!p.filter_fields) {
            return []
          }
          return (
            filters &&
            !Object.keys(filters).some((f) => p[f] !== filters[f].selected)
          )
        })
      : []
    //either use existing, matching map, or set new one
    let displayedMap = existing?.length > 0 ? existing[0] : selected
    setSelectedPMap(displayedMap)

    //get urls to use
    if (displayedMap.url) {
      setUrls(
        Array.isArray(displayedMap.url) ? displayedMap.url : [displayedMap.url]
      )
      //} else if (!!Object.keys(displayedMap).length){
    } else {
      fs.collection('map-links')
        .doc(`${year}-${course}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setUrls([doc.data().url])
          } else {
            setUrls(null)
          }
        })
        .catch(() => setUrls(null))
    }
  }, [filters, courseMap, course, year, setSelectedPMap, getMap])

  const getTheRemainingUnits = () => {
    const onPMapUnitsList = []
    const onPMapUnits = []
    const flatPMap = []

    selectedPMap.map.forEach((y) => {
      y.periods.forEach((tp) => {
        tp.units.forEach((u) => {
          flatPMap.push(u)
        })
      })
    })

    flatPMap.forEach((u) => {
      let onMap = u.name
        ? checkMap(u.name, 'name', filteredFlatMap)
        : null
      if (onMap) {
        onPMapUnitsList.push(u)
      }
    })

    onPMapUnitsList.forEach((u) => {
      onPMapUnits.push(u.name)
    })

    let remainingList = []

    filteredFlatMap.forEach((f) => {
      let x = {...f}
      if (x.creditType === 'unspecifiedCredit') {
        let name = `Level ${x.unitLevel}${x.disciplineDescription ? ` ${capitalise(x.disciplineDescription)}` : ''} - 6CP`
        let sameName = remainingList.findIndex(a => a.name === name)
        x.name = name

        if (sameName > 0) {
          //if thing with same exists, combine cp and replace
          x.creditPoints += remainingList[sameName].creditPoints
          x.allocated = flatPMap.filter(p =>
            p.electiveId === x.id).length*6 >= x.creditPoints
          remainingList[sameName] = x
        } else {
          x.allocated = flatPMap.filter(p => 
             p.electiveId === x.id).length*6 >= x.creditPoints
          remainingList.push(x)
        }
      } else {
        if (!onPMapUnits.includes(x.name)) {
          remainingList.push(x)
        }
      }
    })

    return remainingList
  }

  const bulkAdd = () => {
    const bulkList = []
    const counts = { unit: 0, elective: 0 }

    selectedPMap.map.forEach((y) => {
      y.periods.forEach((tp) => {
        tp.units.forEach((u) => {
          const onMap = u.name
            ? checkMap(u.name, 'name', filteredFlatMap)
            : null
          if (!onMap) {
            let x
            if (u.elective && !u.selectedElective) {
              x = {
                ...u,
                origin: 'MAP',
                blockType: 'COMMENT',
                id: nanoid(),
                status: 'DISPLAY',
              }
              counts.elective += 1
            } else if (u.name) {
              x = {
                ...u,
                origin: 'MAP',
                blockType: 'UNIT',
                id: nanoid(),
                status: 'SUGGESTED',
              }
              counts.unit += 1
            }
            bulkList.push(x)
          }
        })
      })
    })
    setBulkCount(counts)
    setCourseMap((f) => {
      let copy = { ...f }
      // if (!copy.years["Progression Map"]) {
      //   copy.years["Progression Map"] = {
      //     id: nanoid(),
      //     name: "Progression Map",
      //     periods: []
      //   }
      // }
      let keys = Object.keys(copy.years)
      copy.years[keys[keys.length - 1]].periods.push({
        id: nanoid(),
        name: 'Remaining units from map',
        blocks: [...bulkList],
      })
      autosave(copy)
      return copy
    })
    setBlockBulkAdd(true)
    setTimeout(() => {
      setBlockBulkAdd(false)
    }, 5000)
  }

  const remaining = selectedPMap.map ? getTheRemainingUnits() : []

  // render
  return (
    <LoadingScreen loaded={!loading}>
      <div>
        {/* filters */}
        <div className={c.mapFilters}>
          <label>
            <span>Year:</span>
            <fieldset>
              <select
                value={year}
                onChange={(e) => {
                  setYear((f) => {
                    return e.target.value
                  })
                }}>
                {years.map((y) => (
                  <option key={`key-year-${y}`} value={y}>
                    {y}
                  </option>
                ))}
              </select>
              <div className={c.dropdown}>
                {year} <Icon.ChevronDown />
              </div>
            </fieldset>
          </label>
          {filters &&
            Object.keys(filters).map((f) => {
              let filterData = filters[f]
              return (
                <label key={filterData.key}>
                  <span>{capitalise(filterData.key)}:</span>
                  <fieldset>
                    <select
                      value={filterData.selected || filterData.values[0]}
                      onChange={(e) => {
                        let copy = { ...filters }
                        copy[f].selected = e.target.value
                        setFilters(copy)
                      }}>
                      {filterData.values
                        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
                        .map((v) => {
                          return (
                            <option key={`${f}-${v}`} value={v}>
                              {capitalise(v)}
                            </option>
                          )
                        })}
                    </select>
                    <div className={c.dropdown}>
                      {capitalise(filterData.selected || filterData.values[0])}{' '}
                      <Icon.ChevronDown />
                    </div>
                  </fieldset>
                </label>
              )
            })}
        </div>
        <div className={c.progressionMapSection}>
          <section className={c.progressionMapTable}>
            {!!urls?.[0] ? (
              <div className={c.pdfLink}>
                <span>
                  Course maps of {course} for students commencing in {year}:
                </span>
                <div>
                  {urls.map((url, i) => {
                    let chopped = url.split('/')
                    let label = chopped[chopped.length - 1]
                    return (
                      <a href={url} target='_blank' rel='noreferrer' key={i}>
                        <span>{label}</span>
                        <Icon.External />
                      </a>
                    )
                  })}
                </div>
              </div>
            ) : (
              !selectedPMap.map && (
                <span>No course progression map available</span>
              )
            )}

            {selectedPMap.map && (
              <div>
                <PMapTable data={selectedPMap} remaining={remaining} />
              </div>
            )}
          </section>

          {/* bulk add */}
          {selectedPMap.map && !!Object.keys(courseMap?.years || {}).length && (
            <div className={c.progressionMapSection}>
              <section className={c.bulkAdd}>
                <div className={c.add}>
                  <h2>Add remaining units to student's plan in bulk</h2>
                  <button
                    className={c.primaryButton}
                    onClick={bulkAdd}
                    disabled={blockBulkAdd}>
                    {blockBulkAdd ? 'Added' : 'Add'}
                  </button>
                </div>
                {blockBulkAdd && (
                  <div className={c.confirm}>
                    <Icon.CircleCheck /> Added {bulkCount.unit} unit
                    {bulkCount.unit !== 1 && 's'} and {bulkCount.elective}{' '}
                    comment
                    {bulkCount.elective !== 1 && 's'}
                  </div>
                )}
              </section>
            </div>
          )}

          {selectedPMap.notes && (
            <div className={c.progressionMapSection}>
              <section className={c.progressionMapTable}>
                <span>Notes:</span>
                <p className={c.notes}>{selectedPMap.notes}</p>
              </section>
            </div>
          )}
        </div>
      </div>
    </LoadingScreen>
  )
}

export default ProgressionMap
