import React, { useEffect } from 'react'
import { Route, Router } from 'utils/Router'
import './app.scss'
import ContextMenuWrapper from 'components/wrappers/context-menu-wrapper/ContextMenuWrapper'
import StudentDataWrapper from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import Main from 'components/main/Main'
import UserDataWrapper from 'components/wrappers/user-data-wrapper/UserDataWrapper'
import InterfaceDataWrapper from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import ErrorWrapper from 'components/wrappers/error-wrapper/ErrorWrapper'
import View from 'components/view/View'
import preval from 'preval.macro'
import CourseMapDataWrapper from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'

function App() {
  const buildDate = preval`module.exports = new Date().toLocaleString();`

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'uat')
      console.log(
        `%c Built and deployed ${buildDate} `,
        'background: #000; border-radius: 500px; color: gold; padding: 2px'
      )
  }, [buildDate])

  // render

  return (
    <Router suffix='Monash University'>
      <ErrorWrapper>
        <UserDataWrapper>
          <StudentDataWrapper>
            <InterfaceDataWrapper>
              <ContextMenuWrapper>
                <CourseMapDataWrapper>
                  {/* main */}
                  <Route to='/' not='view' title='Course Mapper'>
                    <Main />
                  </Route>
                  <Route to='view' title='Your course map'>
                    <View />
                  </Route>
                </CourseMapDataWrapper>
              </ContextMenuWrapper>
            </InterfaceDataWrapper>
          </StudentDataWrapper>
        </UserDataWrapper>
      </ErrorWrapper>
    </Router>
  )
}

export default App
