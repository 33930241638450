import Icon from 'components/utilities/icons/Icon'
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import c from './modal.module.scss'

const Modal = ({ isShown, content, toggle }) => {
  const [delayedIsShown, setDelayedIsShown] = useState(false)
  const timeoutID = useRef(0)

  useEffect(() => {
    if (timeoutID.current) {
      clearTimeout(timeoutID.current)
    }
    timeoutID.current = setTimeout(
      () => {
        setDelayedIsShown(isShown)
      },
      isShown ? 0 : 500
    )
  }, [isShown])

  return (
    delayedIsShown &&
    ReactDOM.createPortal(
      <div className={c.modal}>
        <div className={`${c.card} ${isShown ? '' : c.hide}`}>
          <div className={c.head}>
            {toggle && (
              <div className={c.closeButton} onClick={toggle}>
                <Icon.X />
              </div>
            )}
          </div>
          <div className={c.content}>{content}</div>
        </div>
        <div className={c.backDrop}></div>
      </div>,
      document.body
    )
  )
}

export default Modal
