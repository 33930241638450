import Icon from 'components/utilities/icons/Icon'
import { ContextMenuData } from 'components/wrappers/context-menu-wrapper/ContextMenuWrapper'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { nanoid } from 'nanoid'
import React, { useContext } from 'react'
import HoverMenu from '../hover-menu/HoverMenu'
import Periods from '../periods/Periods'
import c from './years.module.scss'

const Year = ({ year }) => {
  const { setMenu } = useContext(ContextMenuData)
  const { courseMap, setCourseMap, autosave } = useContext(Data)

  // add teaching period

  const addTeachingPeriod = () => {
    const newMap = { ...courseMap }

    const newPeriod = {
      id: nanoid(),
      name: 'Untitled teaching period',
      blocks: [],
      origin: 'MAP',
    }

    newMap.years[year.name].periods.unshift(newPeriod)

    autosave(newMap)
    setCourseMap(newMap)

    // setCourseMap((f) => {
    //   const newMap = { ...f }

    //   const newPeriod = {
    //     id: nanoid(),
    //     name: 'Untitled teaching period',
    //     blocks: [],
    //     origin: 'MAP',
    //   }

    //   console.log(newMap.years[year.name].periods)

    //   newMap.years[year.name].periods.unshift(newPeriod)

    //   console.log(newMap.years[year.name].periods)

    //   // autosave(newMap)
    //   return newMap
    // })
  }

  // remove year

  const removeYear = () => {
    setCourseMap((f) => {
      const newMap = { ...f }
      delete newMap.years[year.name]
      autosave(newMap)
      return newMap
    })
  }

  // context menu

  const contextMenu = (e) => {
    e.preventDefault()

    setMenu({
      id: year.id,
      position: { x: e.clientX, y: e.clientY },
      items: [
        {
          name: 'Add teaching period',
          icon: <Icon.Add />,
          fn: addTeachingPeriod,
        },
        { name: 'BREAK' },
        {
          name: (
            <span>
              Remove <b>{year.name}</b>
            </span>
          ),
          icon: <Icon.Trash />,
          className: year.periods.length ? c.grey : c.red,
          fn: year.periods.length ? null : removeYear,
        },
      ],
    })
  }

  // render

  return (
    <div className={c.year} key={year.name}>
      <HoverMenu
        onContextMenu={contextMenu}
        title={<h2>{year.name}</h2>}
        style={{ zIndex: 2 }}>
        <button
          title='Add teaching period'
          onClick={addTeachingPeriod}
          className={c.rightMargin}>
          <Icon.RowInsertBottom />
        </button>
      </HoverMenu>
      {year.periods ? (
        <Periods data={year.periods} yearId={year.id} year={year.name} />
      ) : null}
    </div>
  )
}

export default Year
