import LoadingScreen from 'components/utilities/loading-indicator/LoadingScreen'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import { auth, fs } from 'utils/firebase'
import 'firebase/firestore'
import { RouterState } from 'utils/Router'
import { UserData } from '../user-data-wrapper/UserDataWrapper'
import { getStudentData } from 'utils/services'
import { getStudentDetails } from 'utils/mix'

export const CurrentStudent = React.createContext()

const CurrentStudentWrapper = ({ children }) => {
  const [currentStudent, setCurrentStudent] = useState(null)
  const [studentPromise, setStudentPromise] = useState(null)
  const [unsubscribeBookmarks, setUnsubscribeBookmarks] = useState(null)
  const [notFound, setNotFound] = useState(false)
  // const subscribe = fn.httpsCallable('api-subscribe')
  // const getStudentDetails = fn.httpsCallable('api-getStudentDetails')
  const [bookmarks, setBookmarks] = useState(null)
  const { redirect } = useContext(RouterState)
  const { user, updateRecentlyViewed } = useContext(UserData)
  const [searching, setSearching] = useState(false)
  const [mounted, setMounted] = useState(true)

  const addDefaultBookmarks = async (courseEnrolments, student) => {
    let bookmarks = []
    //traverse courseEnrolments
    courseEnrolments.forEach((course) => {
      //get course
      if (course.course && course.courseCode && course.enrolmentYear) {
        bookmarks.push({
          type: 'course',
          code: course.courseCode,
          title: course.course.title,
          year: course.enrolmentYear,
        })
      }
      //get aos'
      if (course.studyUnitSetEnrolments?.['_items']) {
        course.studyUnitSetEnrolments['_items']
          .filter((aos) => {
            return (
              !['RNA', 'COMP-MOD'].includes(aos.studyUnitSet.category) &&
              !aos.endDate &&
              aos.studentConfirmedIndicator === 'Y'
            )
          })
          .forEach((aos) => {
            bookmarks.push({
              type: 'aos',
              code: aos.studyUnitSet.unitSetCode,
              title: aos.studyUnitSet.title,
              year: aos.selectionDate?.slice(0, 4) || course.enrolmentYear,
            })
          })
      }
    })
    //console.log('adding bookmarks', bookmarks)
    fs.collection('bookmarks').doc(student).set({
      bookmarks: bookmarks,
    })
  }

  const listenToBookmarks = useCallback(
    (student) => {
      return fs
        .collection('bookmarks')
        .doc(student)
        .onSnapshot((res) => {
          if (res.data()) {
            if (res.data().bookmarks !== bookmarks) {
              setBookmarks(res.data().bookmarks)
            }
          } else {
            //not in bookmarks collection
            setBookmarks([])
          }
        })
    },
    [bookmarks]
  )

  const findStudent = useCallback(
    (target, redirectTo = null, oneOff = false) => {
      if (!auth.currentUser || searching) {
        // console.log(
        //   'not searching because login is not done or it is already searching'
        // )
        return
      }

      const setStudentData = (target, data) => {
        setCurrentStudent(data)
        updateRecentlyViewed(data)
        sessionStorage.setItem('currentStudent', target)
        setSearching(false)
        setNotFound(false)
        let unsubscribeB = listenToBookmarks(target.toString())
        setUnsubscribeBookmarks(() => unsubscribeB)
      }

      const error = (e) => {
        console.log(e)
        setNotFound(true)
        setSearching(false)
        setStudentPromise(null)
      }

      // console.log('searching')
      setSearching(true)

      const studentRef = fs.collection('students').doc(target.toString())

      if (oneOff) {
        studentRef.get().then((doc) => {
          if (doc.exists) {
            setStudentData(target.toString(), doc.data())
          } else {
            getStudentDetails(target.toString())
              .then((data) => {
                setStudentData(target.toString(), data)
                redirectTo && redirect(redirectTo)
                studentRef.set(data, { merge: true })
              })
              .catch((err) => error(err))
          }
        })
      } else {
        //start big data get
        const theStudentPromise = getStudentDetails(target.toString())
          .then((data) => {
            if (!!JSON.parse(sessionStorage.getItem('currentStudent'))) {
              setStudentData(target.toString(), data)
              studentRef.set(data, { merge: true })
            }
            setStudentPromise(null)
          })
          .catch((err) => error(err))

        setStudentPromise(theStudentPromise)

        //start search / get basic info - name/courses
        getStudentData(target.toString())
          .then((data) => {
            setStudentData(target.toString(), data)
            redirectTo && redirect(redirectTo)
          })
          .catch((err) => error(err))
      }
    },
    [searching, listenToBookmarks, redirect, updateRecentlyViewed]
  )

  useEffect(() => {
    return () => setMounted(false)
  }, [])

  useEffect(() => {
    if (!currentStudent) {
      let storedStudent = JSON.parse(sessionStorage.getItem('currentStudent'))
      if (storedStudent && mounted)
        findStudent(storedStudent.toString(), null, true)
    }
  }, [currentStudent, findStudent, mounted])

  //create bookmarks if none exist
  useEffect(() => {
    currentStudent?.courseEnrolments?.[0]?.id &&
      bookmarks?.length === 0 &&
      addDefaultBookmarks(
        currentStudent.courseEnrolments,
        currentStudent.identifiers?.callistaPersonID
      )
  }, [currentStudent, bookmarks])

  const addBookmark = (added) => {
    //setBookmarks([...bookmarks, added])
    fs.collection('bookmarks')
      .doc(currentStudent.identifiers?.callistaPersonID)
      .update({
        bookmarks: [...bookmarks, added],
      })
  }

  const removeBookmark = (code) => {
    let updated = bookmarks.filter((b) => b.code !== code)

    fs.collection('bookmarks')
      .doc(currentStudent.identifiers?.callistaPersonID)
      .update({
        bookmarks: updated,
      })
  }

  // render

  return (
    <CurrentStudent.Provider
      value={{
        findStudent,
        notFound,
        setNotFound,
        currentStudent,
        studentPromise,
        setCurrentStudent,
        unsubscribeBookmarks,
        bookmarks,
        addBookmark,
        removeBookmark,
      }}>
      {user?.staff ? (
        <LoadingScreen loaded={!searching} message='Finding student'>
          {children}
        </LoadingScreen>
      ) : user ? (
        children
      ) : null}
    </CurrentStudent.Provider>
  )
}

export default CurrentStudentWrapper
