import checkMap from 'components/browser/content/check-map'
import { PlannerSize } from 'components/planner/Planner'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import React, { useContext, useEffect, useState } from 'react'
import Block from '../blocks/Block'
import c from './advanced-standings.module.scss'
import { DragDropData } from 'components/wrappers/drag-drop-wrapper/DragDropWrapper'

const AdvancedStandings = () => {
  const { plannerSize } = useContext(PlannerSize)
  const { courseMap, flatCourseMap } = useContext(Data)
  const { selected } = useContext(DragDropData)
  const [columns, setcolumns] = useState(4)

  // grid size

  useEffect(() => {
    if (plannerSize > 600) setcolumns(4)
    if (plannerSize < 600 && plannerSize > 300) setcolumns(2)
    if (plannerSize < 300) setcolumns(1)
  }, [plannerSize])

  const style = {
    grid: `auto-flow / repeat(${columns}, 1fr)`,
  }

  const renderAdvancedStandings = courseMap?.advancedStandings.map(
    (item, i) => {
      const onMap = checkMap(item.id, 'id', flatCourseMap)
      if (onMap) {
        item.status = 'IN PLAN'
      }

      return <Block key={i} index={i} block={item} disabled={onMap} />
    }
  )

  // render

  return (
    <div className={c.advancedStandings}>
      {selected && <div className={c.disabled}></div>}
      <h2>Advanced standings</h2>
      <div className={c.container} style={style}>
        {renderAdvancedStandings}
      </div>
    </div>
  )
}

export default AdvancedStandings
