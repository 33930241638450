import Icon from 'components/utilities/icons/Icon'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import React, { useContext, useState } from 'react'
import stringToColor from 'utils/colors/string-to-color'
import c from './area-of-study.module.scss'
import SearchAoS from './search-aos/SearchAoS'
import { isPass } from 'utils/data/grades'

const AreaOfStudy = () => {
  // AoS data
  const { courseMap, setCourseMap, autosave, flatCourseMap } = useContext(Data)

  const countAos = (code) => {
    let count = 0
    flatCourseMap.forEach((block) => {
      if (block.aos?.some((aos) => aos.code === code) && 
        (block.grade ? 
          isPass(block.grade)
          : !['DISCONTIN'].includes(block.status)
        )) {
        count++
      }
    })
    return count
  }

  // selected AoS
  const renderAoS = () => {
    return courseMap.aos?.map((item, i) => {
      const { contrast, base } = stringToColor(item.code)
      const warning = parseInt(item.start, 10) > parseInt(courseMap.startingYear, 10) ?
        <span className={c.warning}> - {item.start}<Icon.Alert /></span>
        : null
      const count = countAos(item.code)
      return (
        <li
          key={i}
          onClick={() =>
            editMode &&
            setCourseMap((f) => {
              const newMap = { ...f };
              newMap.aos.splice(i, 1);

              newMap.advancedStandings.forEach((block, i) => {
                block.aos =
                  block.aos?.filter((aos) => aos.code !== item.code) || [];
              });

              Object.values(newMap.years).forEach((year) =>
                year.periods.forEach((period) =>
                  period.blocks.forEach((block, i) => {
                    block.aos =
                      block.aos?.filter((aos) => aos.code !== item.code) || [];
                  })
                )
              );

              autosave(newMap);
              return newMap;
            })
          }
          style={{ color: contrast, backgroundColor: base }}
        >
          <span>
            <span className={c.count}>{count}</span>
            <span>{' '}{item.name}</span>
            {warning}
          </span>
          {editMode && <Icon.Remove />}
        </li>
      );
    })
  }

  // edit mode
  const [editMode, setEditMode] = useState(false)

  // render

  return courseMap ? (
    <div className={c.areaOfStudy}>
      <div className={c.header}>
        <label>Area of stud{courseMap.aos?.length > 1 ? 'ies' : 'y'}</label>
        <button className={c.editButton} onClick={() => setEditMode(!editMode)}>
          <span>{editMode ? 'Cancel' : 'Edit'}</span>
          {editMode ? <Icon.Remove /> : <Icon.Pencil />}
        </button>
      </div>
      <div className={c.listOfAoS}>
        <ul className={c.list}>{renderAoS()}</ul>
        {editMode && <SearchAoS />}
      </div>
    </div>
  ) : null
}

export default AreaOfStudy
