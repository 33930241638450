import React, { useState } from 'react'
import TutorialBookmarks from '../tutorial-graphics/TutorialBookmarks'
import TutorialInformation from '../tutorial-graphics/TutorialInformation'
import TutorialNavigation from '../tutorial-graphics/TutorialNavigation'
import TutorialPlanning from '../tutorial-graphics/TutorialPlanning'
import c from './tutorial-modal.module.scss'

//move those back to the tutorial component later on
const tutorialItems = [
  {
    name: 'Navigation bar',
    description:
      'It allows you to navigate between pages in the browsing panel (panel 2). It does not affect the planning panel (panel 3)',
    graphic: <TutorialNavigation />,
  },
  {
    name: 'Related pages',
    description:
      'Student’s enrolled course and choosen area of studies will appear here.',
    graphic: <TutorialBookmarks />,
  },
  {
    name: 'Information panel',
    description:
      'In this panel, you can browse information, select and add units, or major/minor, etc to the opening plan.',
    graphic: <TutorialInformation />,
  },
  {
    name: 'Planning panel',
    description:
      'The planning panel displays the student’s course map which you can apply changes easily. ',
    graphic: <TutorialPlanning />,
  },
]

const TutorialModal = React.forwardRef(({ onClose, onChange }, ref) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const activeItem = tutorialItems[activeItemIndex]

  const onClickPrevious = () => {
    setActiveItemIndex((i) => --i)
    const previousActiveItem = tutorialItems[activeItemIndex - 1]
    onChange(previousActiveItem.name)
  }

  const onClickNext = () => {
    if (activeItemIndex === tutorialItems.length - 1) {
      onClose()
    } else {
      setActiveItemIndex((i) => ++i)
      const nextActiveItem = tutorialItems[activeItemIndex + 1]
      onChange(nextActiveItem.name)
    }
  }

  return (
    <div ref={ref} className={c.tutorialCard}>
      <button className={c.skipButton} onClick={onClose}>
        Skip tutorial
      </button>
      <div className={c.tutorialContent}>
        {activeItem.graphic}
        <h1 className={c.tutorialTitle}>{activeItem.name}</h1>
        <p>{activeItem.description}</p>
      </div>
      <div className={c.navigation}>
        <button
          className={`${c.previousButton} ${
            activeItemIndex > 0 ? c.active : ''
          }`}
          onClick={onClickPrevious}>
          Previous
        </button>
        <button className={c.nextButton} onClick={onClickNext}>
          {activeItemIndex === tutorialItems.length - 1 ? 'Finish' : 'Next'}
        </button>
      </div>
    </div>
  )
})

export default TutorialModal
