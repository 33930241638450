import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import c from './browser.module.scss'
import Content from './content/Content'
import Sidebar from './sidebar/Sidebar'
import Topnav from './topnav/Topnav'

import { handbookRetrieve, callistaDetails } from '../../utils/services'
import { CurrentStudent } from '../wrappers/student-data-wrapper/StudentDataWrapper' //move this
import Error from '../utilities/error/Error'
import { Route, RouterState } from 'utils/Router'
import Plans from './content/pages/plans/Plans'
import Glossary from './content/pages/glossary/Glossary.jsx'
import LoadingScreen from 'components/utilities/loading-indicator/LoadingScreen'
import Feedback from './feedback/Feedback'
import { nanoid } from 'nanoid'
import Search from '../browser/search-results/Search'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { defaultYear } from 'utils/data/years'

export const BrowserSize = React.createContext()

function Browser({ width }) {
  const studentData = useContext(CurrentStudent)
  const { selectedPlan } = useContext(Data)
  //const { setError } = useContext(ErrorData)
  const { route } = useContext(RouterState)
  const [currentPage, setCurrentPage] = useState(null)
  const [callista, setCallista] = useState({})
  const [loading, setLoading] = useState(false)
  const [browserSize, setBrowserSize] = useState(window.innerWidth * 0.6)
  const [nothingFound, setNothingFound] = useState(false)
  const observer = useMemo(
    () =>
      new ResizeObserver((entries) =>
        setBrowserSize(entries[0].contentRect.width)
      ),
    []
  )

  const measure = useCallback(
    (node) => {
      if (node) observer.observe(node)
    },
    [observer]
  )

  useEffect(() => {
    return () => observer.disconnect()
  }, [observer])

  // transform data

  const relationshipTransform = (data) => {
    return data
      .filter((item) => item.academic_item_type)
      .map((item) => {
        const type = item.academic_item_type.label.toUpperCase()

        return {
          id: nanoid(),
          type: type === 'UNIT' ? 'BLOCK' : type === 'COURSE' ? type : 'AOS',
          blockType: type,
          origin: 'BROWSER',
          creditPoints: item.academic_item_credit_points || 0,
          name: item.academic_item_code,
          description: item.academic_item_name,
        }
      })
  }

  // get page data

  const getPageData = useCallback(
    async (type, code, year) => {
      const deepTransform = (data) => {
        return data.map((item) => {
          item.container = item?.container?.length
            ? deepTransform(item.container)
            : []
          item.relationship = item?.relationship?.length
            ? relationshipTransform(item.relationship)
            : []

          return item
        })
      }

      const transformData = (data) => {
        if (data.type.toLowerCase() !== 'unit') {
          data.data.curriculum.container = data.data?.curriculum?.container
            ? deepTransform(data.data.curriculum.container)
            : []
        }
        return data
      }

      //handbook
      // console.log('start retrieve', Date.now())
      await handbookRetrieve({
        type: type,
        code: code,
        year: year,
      })
        .then((r) => {
          let data
          if (r.found) {
            data = r['_source']
            data['handbook'] = true
            setCurrentPage(transformData(data))
          } else {
            data = {
              //type: type === 'aos' ? 'Area of Study' : type,
              type: type,
              code: code,
              title: '',
              faculty: '',
              implementationYear: year,
              handbook: false,
            }
            setCurrentPage(data)
          }
          // console.log('end handbook retrieve', Date.now())
          setNothingFound(false)
        })
        .catch((error) => {
          console.log(error)
          //setError({ type: '500', popup: true })
          setNothingFound(true)
          //not found page instead of error
        })

      //callista
      callistaDetails({ code: code, type: type })
        .then((r) => {
          // console.log('end callista retrieve', Date.now())
          setCallista(r)
        })
        .catch((error) => {
          console.log(error)
          setCallista({})
        })
      //console.log('fin')
    },
    [] //[setError]
  )

  useEffect(() => {
    if (route.path) {
      const url = new URL(window.location)
      const path = url.pathname.split('/').filter((x) => x.length > 0)

      if (!path.includes('browse')) {
        return
      }

      if (route.data && route.data.code && route.data.year) {
        //get stuff from data
        setLoading(true)
        // console.log()
        getPageData(route.data.type, route.data.code, route.data.year).then(
          () => {
            // console.log('end loading', Date.now())
            setLoading(false)
          }
        )
      } else if (['aos', 'unit', 'course'].some((x) => path.includes(x))) {
        //we know where we are but need to unpack url
        let year = url.searchParams.get('year') || defaultYear
        if (path.length > 1) {
          setLoading(true)
          getPageData(path[1], path[2], year).then(() => {
            // console.log('end loading', Date.now())
            setLoading(false)
          })
        }
      } else if (selectedPlan?.code) {
        // find course using the selected plan
        setLoading(true)
        getPageData(
          'course',
          selectedPlan.code,
          selectedPlan.startingYear
        ).then(() => setLoading(false))
      } else {
        // default - latest course
        let courses = studentData.currentStudent.courseEnrolments
        if (courses?.length > 0) {
          let latest = courses.sort((a, b) => {
            let aDate = new Date(a.enrolmentDate) || 0
            let bDate = new Date(b.enrolmentDate) || 0
            return aDate < bDate ? 1 : aDate > bDate ? -1 : 0
          })
          setLoading(true)
          getPageData(
            'course',
            latest[0].courseCode,
            latest[0].enrolmentYear
          ).then(() => setLoading(false))
        } else {
          setLoading(true)
          getPageData('course', 'B2000', defaultYear).then(() =>
            setLoading(false)
          )
        }
        //or just Link/Redirect to that page
      }
    }
  }, [
    getPageData,
    route,
    selectedPlan,
    studentData.currentStudent.courseEnrolments,
  ])

  // render

  return (
    <BrowserSize.Provider value={{ browserSize, setBrowserSize }}>
      <div className={c.browser} ref={measure} style={{ width: `${width}vw` }}>
        <Topnav />
        <Sidebar />
        <Route to='browse'>
          <LoadingScreen loaded={!loading}>
            {nothingFound ? (
              <Error message={'This page does not exist'} />
            ) : (
              <Content pageData={currentPage} callista={callista} />
            )}
          </LoadingScreen>
        </Route>
        <Route to='plans'>
          <Plans />
        </Route>
        <Route to='feedback'>
          <Feedback />
        </Route>
        <Route to='glossary'>
          <Glossary />
        </Route>
        <Route to='search'>
          <Search />
        </Route>
      </div>
    </BrowserSize.Provider>
  )
}

export default Browser
