import React from 'react'

const Icon = ({ children }) => {
  // render
  return <>{children}</>
}

export default Icon

// icons - - -

// Trash
Icon.Trash = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3M4 7h16m-10 4v6m4-6v6' />
  </svg>
)

// Pencil
Icon.Pencil = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4' />
    <line x1='13.5' y1='6.5' x2='17.5' y2='10.5' />
  </svg>
)

// Add
Icon.Add = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M12 5v14m-7-7h14' />
  </svg>
)

// Remove
Icon.Remove = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <line x1='18' y1='6' x2='6' y2='18' />
    <line x1='6' y1='6' x2='18' y2='18' />
  </svg>
)

// Info
Icon.Info = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#000'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <circle cx='12' cy='12' r='9' />
    <path d='M12 8h.01M11 12h1v4h1' />
  </svg>
)

// DotsVertical
Icon.DotsVertical = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <circle cx='12' cy='12' r='1' />
    <circle cx='12' cy='19' r='1' />
    <circle cx='12' cy='5' r='1' />
  </svg>
)

// DotsHorizontal
Icon.DotsHorizontal = () => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <path d='M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z' />
  </svg>
)

// ChevronUp
Icon.ChevronUp = () => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <path d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z' />
  </svg>
)

// ChevronDown
Icon.ChevronDown = () => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' />
  </svg>
)

// ChevronLeft
Icon.ChevronLeft = () => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
  </svg>
)

// ChevronRight
Icon.ChevronRight = () => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
  </svg>
)

// Search
Icon.Search = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#333333'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <circle cx='10' cy='10' r='7' />
    <line x1='21' y1='21' x2='15' y2='15' />
  </svg>
)

// ResizeLeft
Icon.ResizeLeft = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    stroke='currentColor'
    fill='none'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <rect x='4' y='4' width='16' height='16' rx='2' />
    <path d='M9 4v16' />
  </svg>
)

// ResizeRight
Icon.ResizeRight = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <rect x='4' y='4' width='16' height='16' rx='2' />
    <path d='M15 4v16' />
  </svg>
)

// ResizeEven
Icon.ResizeEven = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <rect x='4' y='4' width='16' height='16' rx='2' />
    <path d='M12 4v16' />
  </svg>
)

// Adjust
Icon.Adjust = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <circle cx='6' cy='10' r='2' />
    <circle cx='12' cy='16' r='2' />
    <circle cx='18' cy='7' r='2' />
    <path d='M6 4v4m0 4v8m6-16v10m0 4v2m6-16v1m0 4v11' />
  </svg>
)

// DocumentDownload
Icon.DocumentDownload = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M14 3v4a1 1 0 0 0 1 1h4' />
    <path d='M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2zm-5-10v6' />
    <path d='M9 14l3 3 3-3' />
  </svg>
)

// Enter
Icon.Enter = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M18 6V12C18 12.7956 17.6839 13.5587 17.1213 14.1213C16.5587 14.6839 15.7957 15 15 15H5M5 15L9 11M5 15L9 19'
      stroke='#747474'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

// Landing
Icon.Landing = () => (
  <svg
    width='339'
    height='230'
    viewBox='0 0 339 230'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect opacity='0.5' y='144' width='339' height='74' rx='8' fill='#E8EEF4' />
    <rect
      x='118.65'
      y='62.7041'
      width='67.8'
      height='120.593'
      rx='4'
      fill='#A1B6CA'
    />
    <rect
      x='79.0996'
      y='62.7041'
      width='33.9'
      height='120.593'
      rx='4'
      fill='#A1B6CA'
    />
    <rect
      x='192.1'
      y='62.7041'
      width='67.8'
      height='120.593'
      rx='4'
      fill='#204F88'
    />
    <rect
      x='68.7998'
      y='50'
      width='201.4'
      height='146'
      rx='7'
      stroke='#A1B6CA'
      strokeWidth='2'
    />
    <g filter='url(#filter0_d)'>
      <rect x='15' y='125' width='45' height='45' rx='22.5' fill='#C5D2DF' />
    </g>
    <path
      d='M33.6 137H41.4C42.0896 137 42.7509 137.274 43.2385 137.762C43.7261 138.249 44 138.91 44 139.6V157.8L37.5 153.9L31 157.8V139.6C31 138.91 31.2739 138.249 31.7615 137.762C32.2491 137.274 32.9104 137 33.6 137Z'
      stroke='white'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g filter='url(#filter1_d)'>
      <rect x='192' y='8' width='35' height='35' rx='17.5' fill='#C5D2DF' />
    </g>
    <path
      d='M208.685 29.1372C211.875 29.1372 214.46 26.6508 214.46 23.5837C214.46 20.5166 211.875 18.0303 208.685 18.0303C205.495 18.0303 202.909 20.5166 202.909 23.5837C202.909 26.6508 205.495 29.1372 208.685 29.1372Z'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M217.761 32.3109L212.811 27.5508'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g filter='url(#filter2_d)'>
      <rect x='281' y='67' width='30' height='30' rx='15' fill='#C5D2DF' />
    </g>
    <path
      d='M296.178 87.9775V87.9856'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M296.083 84.1113C296.06 83.7267 296.169 83.3455 296.393 83.025C296.617 82.7046 296.944 82.4622 297.324 82.3344C297.791 82.1642 298.21 81.8929 298.548 81.5419C298.886 81.191 299.134 80.7699 299.273 80.3119C299.412 79.8539 299.437 79.3715 299.347 78.9026C299.257 78.4337 299.054 77.9912 298.754 77.6099C298.454 77.2285 298.066 76.9188 297.619 76.705C297.173 76.4912 296.68 76.3793 296.18 76.3779C295.681 76.3766 295.188 76.486 294.74 76.6974C294.292 76.9088 293.902 77.2165 293.6 77.5962'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g filter='url(#filter3_d)'>
      <rect x='250' y='166' width='40' height='40' rx='4' fill='white' />
    </g>
    <path
      d='M260 180H280.8'
      stroke='#C5D2DF'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M260 192H280.8'
      stroke='#C5D2DF'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <filter
        id='filter0_d'
        x='5'
        y='117'
        width='65'
        height='65'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_d'
        x='182'
        y='0'
        width='55'
        height='55'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <filter
        id='filter2_d'
        x='271'
        y='59'
        width='50'
        height='50'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <filter
        id='filter3_d'
        x='230'
        y='150'
        width='80'
        height='80'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='10' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

// Logout
Icon.Logout = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2' />
    <path d='M7 12h14l-3-3m0 6l3-3' />
  </svg>
)

// Help
Icon.Help = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M12 13.5a1.5 1.5 0 0 1 1-1.5 2.6 2.6 0 1 0-3-4' />
    <circle cx='12' cy='12' r='9' />
    <path d='M12 17v.01' />
  </svg>
)

// External
Icon.External = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M11 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-5m-7 1L20 4m-5 0h5v5' />
  </svg>
)

// Home
Icon.Home = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7' />
    <path d='M9 21v-6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v6M5 12H3l9-9 9 9h-2' />
  </svg>
)

// Bookmark
Icon.Bookmark = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    {/* <path stroke='none' d='M0 0h24v24H0z' /> */}
    <path d='M9 4h6a2 2 0 0 1 2 2v14l-5-3l-5 3v-14a2 2 0 0 1 2 -2' />
  </svg>
)

// Book
Icon.Book = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor '
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0' />
    <path d='M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0' />
    <line x1='3' y1='6' x2='3' y2='19' />
    <line x1='12' y1='6' x2='12' y2='19' />
    <line x1='21' y1='6' x2='21' y2='19' />
  </svg>
)

// Plus
Icon.Plus = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <line x1='12' y1='5' x2='12' y2='19' />
    <line x1='5' y1='12' x2='19' y2='12' />
  </svg>
)

// Minus
Icon.Minus = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <line x1='5' y1='12' x2='19' y2='12' />
  </svg>
)

// QuestionMark
Icon.QuestionMark = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M8 8 a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4' />
    <line x1='12' y1='19' x2='12' y2='19.01' />
  </svg>
)

// Menu
Icon.Menu = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <line x1='4' y1='8' x2='20' y2='8' />
    <line x1='4' y1='16' x2='20' y2='16' />
  </svg>
)

// X
Icon.X = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#333333'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <line x1='18' y1='6' x2='6' y2='18' />
    <line x1='6' y1='6' x2='18' y2='18' />
  </svg>
)

// Comment
Icon.Comment = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M3 20l1.3-3.9A9 8 0 1 1 7.7 19L3 20m9-8v.01M8 12v.01m8-.01v.01' />
  </svg>
)

// Unit
Icon.Unit = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <rect x='8' y='8' width='12' height='12' rx='2' />
    <line x1='4' y1='4' x2='4' y2='4.01' />
    <line x1='8' y1='4' x2='8' y2='4.01' />
    <line x1='12' y1='4' x2='12' y2='4.01' />
    <line x1='16' y1='4' x2='16' y2='4.01' />
    <line x1='4' y1='8' x2='4' y2='8.01' />
    <line x1='4' y1='12' x2='4' y2='12.01' />
    <line x1='4' y1='16' x2='4' y2='16.01' />
  </svg>
)

// Chilli
Icon.Chilli = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M13 11c0 2.21-2.239 4-5 4s-5-1.79-5-4a8 8 0 1 0 16 0 3 3 0 0 0-6 0m3-3c0-2 2-4 4-4' />
  </svg>
)

// Pizza
Icon.Pizza = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M12 21.5c-3.04 0 -5.952 -.714 -8.5 -1.983l8.5 -16.517l8.5 16.517a19.09 19.09 0 0 1 -8.5 1.983z' />
    <path d='M5.38 15.866a14.94 14.94 0 0 0 6.815 1.634a14.944 14.944 0 0 0 6.502 -1.479' />
    <path d='M13 11.01v-.01' />
    <path d='M11 14v-.01' />
  </svg>
)

// Pyramid
Icon.Pyramid = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M3 17l9 4 9-4-9-14zm9-14v18' />
  </svg>
)

// Rainbow
Icon.Rainbow = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M22 17c0-5.523-4.477-10-10-10S2 11.477 2 17m16 0a6 6 0 1 0-12 0m8 0a2 2 0 1 0-4 0' />
  </svg>
)

// RowInsertBottom
Icon.RowInsertBottom = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M20 6v4a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1z' />
    <line x1='12' y1='15' x2='12' y2='19' />
    <line x1='14' y1='17' x2='10' y2='17' />
  </svg>
)

// Export
Icon.Export = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#000'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M14 3v4a1 1 0 0 0 1 1h4' />
    <path d='M11.5 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v5m-5 6h7m-3-3l3 3-3 3' />
  </svg>
)

// SquiggleyArrowLeft
Icon.SquiggleyArrowLeft = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#000'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M7 9.996H3v4' />
    <path d='M21 11.996c-.887-1.285-2.48-2.033-4-2-1.52-.033-3.113.715-4 2-.887 1.284-2.48 2.033-4 2-1.52.033-3-1-4-2l-2-2' />
  </svg>
)

// Lock
Icon.Lock = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#000'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M8 11V7a4 4 0 0 1 8 0v4' />
    <rect x='5' y='11' width='14' height='10' rx='2' />
    <circle cx='12' cy='16' r='1' />
  </svg>
)

//Square-x

Icon.SquareX = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#000'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <rect x='4' y='4' width='16' height='16' rx='2' />
    <path d='M10 10l4 4m0 -4l-4 4' />
  </svg>
)

Icon.SquareMinus = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#000'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <rect x='4' y='4' width='16' height='16' rx='2' />
    <line x1='9' y1='12' x2='15' y2='12' />
  </svg>
)

Icon.SquarePlus = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#000'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <rect x='4' y='4' width='16' height='16' rx='2' />
    <line x1='9' y1='12' x2='15' y2='12' />
    <line x1='12' y1='9' x2='12' y2='15' />
  </svg>
)

// Resize
Icon.Resize = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 11v8a1 1 0 0 0 1 1h8m-9 -14v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1' />
    <path d='M4 12h7a1 1 0 0 1 1 1v7' />
  </svg>
)

// Copy
Icon.Copy = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <rect x='8' y='8' width='12' height='12' rx='2' />
    <path d='M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2' />
  </svg>
)

Icon.Eye = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <circle cx='12' cy='12' r='2' />
    <path d='M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7' />
  </svg>
)

Icon.CircleCheck = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
      stroke='#currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9 12L11 14L15 10'
      stroke='#currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

Icon.Alert = () => (
  <svg
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z'
      stroke='#currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 5.83336V8.50003'
      stroke='#currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 11.1666H8.00667'
      stroke='#currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

// Note
Icon.Note = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    stroke='#000'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M13 20v-6a1 1 0 0 1 1-1h6V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7m0 0l7-7' />
  </svg>
)

// MinimisedNote
Icon.MinimisedNote = () => (
  <svg width='24' height='24' fill='none'>
    <g clipPath='url(#a)'>
      <path
        d='M19.35 19.444H4.347a1.8 1.8 0 0 1-1.801-1.8V8.8a1.5 1.5 0 0 1 1.5-1.5h5.1l3-3 3 3h4.5a1.5 1.5 0 0 1 1.5 1.5v8.85a1.8 1.8 0 0 1-1.794 1.794z'
        fill='#FBF3B3'
        stroke='#E9E092'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path
        d='M5.846 15.4a.9.9 0 0 1 .9-.9h6.6a.9.9 0 0 1 0 1.8h-6.6a.9.9 0 0 1-.9-.9z'
        fill='#E9E092'
      />
      <rect x='5.846' y='11.1' width='12' height='1.8' rx='.9' fill='#E9E092' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)

// FileX
Icon.FileX = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    stroke='#000'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M14 3v4a1 1 0 0 0 1 1h4' />
    <path d='M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2zm-7-9l4 4m0-4l-4 4' />
  </svg>
)
