import React, { useContext } from 'react'
import Accordion from '../../modules/accordion/Accordion'
import c from './overview.module.scss'
import ProgressBar from './progress-bar/ProgressBar'
import htmlParse from 'html-react-parser'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import { capitalise } from 'utils/transforms/string-transforms'
import { grades } from 'utils/data/grades.js'

// TODO: make those lists reusable
// List of non creditable grades
/*const nonCreditableGradeSet = new Set([
  'WH',
  'DEF',
  'NS',
  'N',
  'NH',
  'WN',
  'NSR',
  'WDN',
  'WI',
])*/
const nonCreditableGradeSet = new Set(
  Object.keys(grades).filter((g) => grades[g].result !== 'PASS')
)

// List of creditable unit statuses
const creditableUnitStatusSet = new Set(['ENROLLED', 'COMPLETED', 'DUPLICATE'])
const creditableCreditStatusSet = new Set(['GRANTED'])

const Overview = ({ pageData }) => {
  const { currentStudent } = useContext(CurrentStudent)
  const currentCourse = currentStudent.courseEnrolments?.filter(
    (x) => x.courseCode === pageData?.code
  )[0]

  // const [allNotes, setAllNotes] = useState(false)

  const whitelist = {
    course: {
      overview: 'Overview',
      /*Special_notes_to_students: 'Notes',
      offering: 'Mode and location',
      double_degrees: 'Double degrees',
      learning_outcomes: 'Learning outcomes',
      Professional_accreditation: 'Professional recognition',
      lo_additional_information: 'injected into learning outcomes',
      structure: 'Structure',
      Progression_to_further_studies: 'Progression to further studies',
      credit_restrictions: 'Additional Information',
      academic_contacts: 'Course coodinator(s)',*/
    },
    'area of study': {
      handbook_description: 'Overview',
    },
    unit: {
      handbook_synopsis: 'Overview',
    },
  }

  const overviewData = () => {
    const type = pageData.type.toLowerCase()
    return Object.keys(whitelist[type]).map((section, i) => {
      let content = pageData.data[section] || []

      if (content.length > 2) {
        return (
          <section key={i}>
            <Accordion
              header={<h2>{whitelist[type][section]}</h2>}
              expanded={true}>
              {htmlParse(content)}
            </Accordion>
          </section>
        )
      }

      return null
    })
  }

  const flattenUnitEnrollment = (course) => {
    const teachingPeriods = course.teachingPeriods
    const advancedStandings = course.advancedStandings
    const teachingPeriodsEnrollment = []
    const flatUnitEnrollment = []

    //Add advanced standings to unit enrollment
    if (advancedStandings) {
      Object.values(advancedStandings).forEach((as, i) => {
        if (as) {
          flatUnitEnrollment.push({
            type: as.unitCode ? 'specified credit' : 'unspecified credit',
            unitCode: as.unitCode,
            creditPoints: as.creditPoints,
            unitLevel: as.unitLevel,
            status: as.grantingStatus,
          })
        }
      })
    }

    //Add unit groups to teaching periods enrollment
    if (teachingPeriods) {
      Object.values(teachingPeriods).forEach((units, i) => {
        if (units?.unitEnrolments) {
          teachingPeriodsEnrollment.push(units?.unitEnrolments)
        }
      })
    }

    //Add units to unit enrollment
    teachingPeriodsEnrollment.forEach((units, i) => {
      Object.values(units).forEach((unit, i) => {
        if (unit) {
          flatUnitEnrollment.push({
            type: 'unit',
            unitCode: unit.studyUnit.unitCode,
            creditPoints: unit.studyUnit.maxCreditPoints,
            unitLevel: unit.studyUnit.level,
            status: unit.status,
            grade: unit.grade || unit.duplicateOf?.[0].grade || null,
          })
        }
      })
    })

    return flatUnitEnrollment
  }

  const flatUnitEnrollment =
    currentCourse && flattenUnitEnrollment(currentCourse)

  let unitLevels = []
  flatUnitEnrollment?.forEach((item, i) => {
    //Check unit level already exist and unit or credit is creditable
    if (
      unitLevels.indexOf(item.unitLevel) === -1 &&
      item.unitLevel !== '0' &&
      !nonCreditableGradeSet.has(item.grade) &&
      (creditableUnitStatusSet.has(item.status) ||
        creditableCreditStatusSet.has(item.status))
    ) {
      unitLevels.push(item.unitLevel)
    }
  })

  unitLevels = unitLevels.sort()

  const creditsLevelCount = (level) => {
    let creditPoints = 0
    flatUnitEnrollment?.forEach((item, i) => {
      if (
        item.type === 'unit' &&
        !nonCreditableGradeSet.has(item.grade) &&
        creditableUnitStatusSet.has(item.status)
      ) {
        if (item.unitLevel === level) {
          creditPoints += item.creditPoints || 0
        }
      }
      if (
        (item.type === 'specified credit' || 'unspecified credit') &&
        creditableCreditStatusSet.has(item.status)
      ) {
        if (item.unitLevel === level) {
          creditPoints += item.creditPoints || 0
        }
      }
    })
    return creditPoints
  }

  const purpleBlob = (
    <span style={{ background: '#604088' }} className={c.keyBlob}>
      {' '}
    </span>
  )

  const notes = currentCourse?.notes?.['_items']
    //latest first
    .sort((a, b) => 
      new Date(b.updateOn) - new Date(a.updateOn)
    )
    //initially show only from past year
    // .filter((note) => {
    //   if (allNotes) return true
    //   const now = new Date()
    //   const noteDate = new Date(note.updateOn)
    //   let months = 
    //     (now.getFullYear() - noteDate.getFullYear()) * 12
    //     - noteDate.getMonth()
    //     + now.getMonth()
    //   return months <= 12
    // })
    //group by type
    .sort((a, b) => b.noteType > a.noteType ? 1 : a.noteType > b.noteType ? -1 : 0)
    //lay em out
    .map((note) => {
      return (
        <div className={c.note} key={note.id}>
          <div className={c.noteHeader}>
            <span className={c.type}>{note.noteType} note</span>
            <span className={c.secondary}>{new Date(note.updateOn).toLocaleDateString()}</span>
          </div>
          <span>{note.noteText}</span>
        </div>
      )
    })

  const courseProgress = () => {
    const progress = currentCourse.progression
    return (
      <section className={c.courseProgress}>
        <div className={c.sectionContents}>
          <h2>
            Completion Progress: {progress.percentCompleted}% ({' '}
            {progress.creditPointsTotalAchieved} /{' '}
            {progress.creditPointsRequired} credit points )
          </h2>
          <ProgressBar
            adv={parseInt(progress.creditPointsAdvancedStanding)}
            comp={parseInt(progress.creditPointsPassed)}
            enr={
              parseInt(progress.creditPointsCurrentEnrolled) +
              parseInt(progress.creditPointsFutureEnrolled)
            }
            total={parseInt(progress.creditPointsRequired)}
          />
        </div>
        {unitLevels && (
          <Accordion header={<h2>CP/Level Counts</h2>}>
            {unitLevels.map((level, i) => {
              return (
                <p key={i}>
                  Level {level}: {creditsLevelCount(level)}
                </p>
              )
            })}
          </Accordion>
        )}
        {currentCourse.notes?.totalRecords > 0 && (
          <Accordion header={<h2> Student Course Notes</h2>} expanded={false}>
            {notes}
            {/* {!allNotes && notes.length < currentCourse.notes?.totalRecords && (
              <div onClick={() => setAllNotes(true)} className={c.showMore}>
                Show all ...
              </div>
            )} */}
          </Accordion>
        )}
        {currentCourse.advancedStandings && (
          <Accordion header={<h2>Advanced standing</h2>}>
            {currentCourse.advancedStandings.map((as, i) => {
              return (
                <div className={c.advancedStanding} key={i}>
                  <p>
                    Title: {as.unitCode ? as.unitCode + ' - ' : null}
                    {as.unitTitle ||
                      `Level ${as.unitLevel} ${capitalise(
                        as.disciplineDescription
                      )}`}
                  </p>
                  <p>
                    Status: {purpleBlob} {capitalise(as.recognitionType)} -{' '}
                    {capitalise(as.grantingStatus)}
                  </p>
                  <p>
                    Type: {capitalise(as.creditType.split('Credit')[0])} Credit
                  </p>
                  <p>Credit: {as.creditPoints || 0}CP</p>
                </div>
              )
            })}
          </Accordion>
        )}
      </section>
    )
  }

  // render

  return (
    <div className={c.overview}>
      {currentCourse && courseProgress()}
      {pageData?.handbook && overviewData()}
      {!pageData && (
        <section>
          <p>Not Data Found</p>
        </section>
      )}
    </div>
  )
}

export default Overview
