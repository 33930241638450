import React from 'react';

/**
 * Makes the portion of text which matches substring bold
 * @param {String} text
 * @param {String} substring
 */
export const makeSubstringBold = (target, string) => {
  let rx = new RegExp(target.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), 'gi');
  const replacements = string.match(rx) || [];
  const newText = [].concat(
    ...string
      .split(rx)
      .map((n, i) =>
        i < replacements.length ? [n, <b>{replacements[i]}</b>] : n
      )
  );
  return newText;
};
