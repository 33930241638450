import React from 'react'
import c from './view-plan.module.scss'
import VPeriod from './VPeriod'

const VYear = ({ year, hideFailed }) => {
  // render

  return (
    <div className={c.year}>
      <h3>{year.name}</h3>
      {year.periods.map((period, i) => (
        <VPeriod period={period} key={i} hideFailed={hideFailed} />
      ))}
    </div>
  )
}

export default VYear
