import { BrowserSize } from 'components/browser/Browser'
import Block from 'components/planner/mapper/blocks/Block'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { DragDropData } from 'components/wrappers/drag-drop-wrapper/DragDropWrapper'
import { nanoid } from 'nanoid'
import React, { useContext, useEffect, useState } from 'react'
import { gradeStatus } from 'utils/data/grades'
import checkMap from '../../check-map'
import c from './progression-map.module.scss'

const PMapBlocks = ({ data, remaining }) => {
  const { selected } = useContext(DragDropData)

  // size
  const { browserSize } = useContext(BrowserSize)
  const [columns, setcolumns] = useState(4)

  useEffect(() => {
    if (browserSize > 750) setcolumns(4)
    if (browserSize < 750 && browserSize > 450) setcolumns(2)
    if (browserSize < 450) setcolumns(1)
  }, [browserSize])

  const style = {
    grid: `auto-flow / repeat(${columns}, 1fr)`,
  }

  // list

  const { courseMap, flatCourseMap, eligibleAoS } = useContext(Data)
  const [renderList, setRenderList] = useState([])

  useEffect(() => {
    const filteredFlatMap = flatCourseMap.filter((item) => {
      const grade = gradeStatus(item.grade)
      if (['DISCONTIN'].includes(item.status)) return false
      if (['FAIL', 'WITHDRAWN'].includes(grade)) return false
      return true
    })

    setRenderList(
      data
        .map((item) => {
          const onMap =
            item.name || item.selectedElective
              ? checkMap(item.name, 'name', filteredFlatMap)
              : false

          if (item.elective) {
            const exists = checkMap(
              item.electiveId,
              'electiveId',
              filteredFlatMap
            )
            if (exists && !selected) {
              item.electiveId = nanoid()
              item.id = nanoid()
            }
          }

          if (onMap) {
            item.id = nanoid()
            const grade = gradeStatus(onMap.grade)
            item.status =
              onMap.status && !['DISCONTIN'].includes(onMap.status)
                ? onMap.status
                : null
            item.grade =
              onMap.grade &&
              onMap.grade &&
              !['FAIL', 'WITHDRAWN'].includes(grade)
                ? onMap.grade
                : null
            if (onMap.blockType === 'CREDIT') {
              item.category = onMap.category || null
              item.grantingStatus = onMap.grantingStatus || null
            }
          }

          if (!onMap) {
            item.status = null
          }

          return { item, onMap }
        })
        .map((data, i) => {
          return (
            <Block
              block={data.item}
              key={data.item.id || i}
              disabled={data.onMap}
              electives={remaining}
            />
          )
        })
    )
  }, [courseMap?.aos, data, eligibleAoS, flatCourseMap, remaining, selected])

  // render

  return (
    <div className={c.blocks} style={style}>
      {renderList}
    </div>
  )
}

export default PMapBlocks
