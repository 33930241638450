import React from 'react'
import c from './creditPointsTag.module.scss'

const CreditPointsTag = ({ blockType, origin, elective, creditPoints }) => {
  //Do not display tag on comment boxes
  if (blockType === 'COMMENT') return null

  //Do not display tag on elective blocks
  if (elective) return null

  //Do not display tag on handbook tab
  if (origin === 'BROWSER') return null

  if (
    origin === 'MAP' ||
    (origin === 'PMAP' && !elective) ||
    origin === 'CREDIT'
  ) {
    //Do not display tag if credit points count is 6
    if (parseInt(creditPoints || '') === 6) {
      return null
    }
    //Display 0CP tag for credit blocks
    if (creditPoints === null) {
      return <div className={c.creditPointsTag}>0CP</div>
    }
    return <div className={c.creditPointsTag}>{creditPoints + 'CP'}</div>
  }
}

export default CreditPointsTag
