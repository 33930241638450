import React, { useState, useContext } from 'react'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'

import c from './plan-name.module.scss'
import HoverMenu from '../mapper/hover-menu/HoverMenu'
import Icon from 'components/utilities/icons/Icon'

const PlanName = () => {
  const { planName, setPlanName, setCourseMap, autosave } = useContext(Data)
  const [editName, setEditName] = useState(false)
  const startEdit = () => {
    setPlanName(planName)
    setEditName(true)
  }

  //Get Name
  const changeName = (e) => {
    e.preventDefault()
    setEditName(false)

    setCourseMap((f) => {
      const newMap = { ...f }
      newMap.planName = planName
      console.log(planName)
      autosave(newMap)
      return newMap
    })
  }

  // render

  return (
    <div className={c.planNameContainer}>
      {editName && (
        <h2>
          <form onSubmit={changeName}>
            <input
              autoFocus
              value={planName}
              onChange={(e) => {
                setPlanName(e.target.value)
              }}
            />
          </form>
        </h2>
      )}

      {!editName && (
        <HoverMenu title={<h2>{planName}</h2>}>
          <button onClick={startEdit}>
            <Icon.Pencil></Icon.Pencil>
          </button>
        </HoverMenu>
      )}
    </div>
  )
}

export default PlanName
