import Icon from 'components/utilities/icons/Icon'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import { nanoid } from 'nanoid'
import React, { useContext } from 'react'
import Year from './Year'
import c from './years.module.scss'

const Years = () => {
  const { courseMap, setCourseMap, autosave } = useContext(Data)
  const { editMode } = useContext(Interface)

  // add year

  const addYear = () => {
    setCourseMap((f) => {
      const newMap = { ...f }
      const newYear =
        +Object.keys(newMap.years).pop() + 1 || new Date().getFullYear() //if no years already (eg. just advanced standing)
      newMap.years[newYear] = {
        name: newYear.toString(),
        periods: [
          {
            id: nanoid(),
            name: 'Untitled teaching period',
            blocks: [],
            origin: 'MAP',
          },
        ],
      }
      autosave(newMap)
      return newMap
    })
  }

  // render

  return (
    <div className={c.years}>
      {courseMap &&
        Object.values(courseMap.years).map((year, i) => (
          <Year key={i} {...{ year }} />
        ))}
      {editMode ? null : (
        <div className={c.newYear}>
          <button onClick={addYear} id='add-new-year'>
            <Icon.RowInsertBottom /> Add new year
          </button>
        </div>
      )}
    </div>
  )
}

export default Years
