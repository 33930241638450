import Icon from 'components/utilities/icons/Icon'
import LoadingScreen from 'components/utilities/loading-indicator/LoadingScreen'
import { TutorialContext } from 'components/utilities/tutorial/TutorialProvider'
import { ContextMenuData } from 'components/wrappers/context-menu-wrapper/ContextMenuWrapper'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import { UserData } from 'components/wrappers/user-data-wrapper/UserDataWrapper'
import { formatDistanceToNow } from 'date-fns'
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import stringToColor from 'utils/colors/string-to-color'
import { arrayRemove, fs } from 'utils/firebase'
import { RouterState } from 'utils/Router'
import c from './plans.module.scss'
import Preview from './preview/Preview'
import { isEqual } from 'lodash'
import { unitAos } from 'utils/services'
import compileMap from 'utils/compile-map/compile-map'
import { analytics } from 'utils/firebase'
import { getFacultyFromCourseCode } from 'utils/data/faculty'
import { isPass } from 'utils/data/grades'

const Plans = () => {
  const { selectedPlan, setSelectedPlan, courseMap, setCourseMap } =
    useContext(Data)
  const { currentStudent, studentPromise } = useContext(CurrentStudent)
  const { showPlanningPanel, setShowPlanningPanel } = useContext(Interface)
  const { user } = useContext(UserData)
  const [plans, setPlans] = useState([])
  const [plansWithData, setPlansWithData] = useState([])
  const [loading, setLoading] = useState(true)
  const [mounted, setMounted] = useState(true)
  const [blockRendering, setBlockRendering] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('Loading plans')

  useEffect(() => {
    return () => setMounted(false)
  }, [])

  // get list of plans after loading student

  useEffect(() => {
    if (
      currentStudent?.courseEnrolments?.[0]?.id &&
      loading &&
      !blockRendering
    ) {
      fs.collection('students')
        .doc(currentStudent.identifiers?.callistaPersonID)
        .onSnapshot((doc) => {
          if (mounted) {
            setPlans((doc.exists ? doc.data() : { plans: [] }).plans || [])
            setLoading(false)
          }
        })
    }
  }, [currentStudent, loading, blockRendering, courseMap, mounted])

  // get plans from firestore

  useEffect(() => {
    if (plans) {
      plans.forEach((id) =>
        fs
          .collection('student-plans')
          .doc(id)
          .onSnapshot((doc) => {
            const data = doc.data()
            if (data && mounted) {
              setPlansWithData((f) => {
                const list = f.some((item, i) => {
                  if (item.id === data.id) {
                    if (!isEqual(item, data)) f.splice(i, 1, data)
                    return true
                  } else {
                    return false
                  }
                })
                  ? f
                  : [...f, data]

                return list.sort(
                  (a, b) =>
                    (b.logs.edited || b.logs.created) -
                    (a.logs.edited || a.logs.created)
                )
              })
            }
          })
      )
    }
  }, [plans, courseMap, mounted])

  // compile aos name

  const aosName = (aos) => {
    return `${
      aos.category?.slice(0, 2).toUpperCase() ||
      aos.aosType?.slice(0, 2).toUpperCase()
    } - ${aos.unitSetCode || aos.aos} ${aos.title || aos.aosName}`
  }

  // create new plan

  const [selectEnrolment, setSelectEnrolment] = useState(false)

  const checkEnrolments = () => {
    if (currentStudent.courseEnrolments?.length > 1) {
      setSelectEnrolment(true)
    } else {
      createNewPlan(0)
    }
  }

  const waitAndCreateNewPlan = (index) => {
    if (studentPromise) {
      setLoading(true)
      setBlockRendering(true)
      setLoadingMessage('Loading student enrolment record')
      if (selectEnrolment) setSelectEnrolment(false)
      studentPromise.then(() => {
        setLoading(false)
        createNewPlan(index)
      })
    } else {
      createNewPlan(index)
    }
  }

  const createNewPlan = (index, plan) => {
    if (selectEnrolment) setSelectEnrolment(false)
    const ref = fs.collection('student-plans').doc()
    const newPlan = plan
      ? { ...plan }
      : compileMap(ref.id, currentStudent, index, user.displayName)

    if (plan) {
      newPlan.planName = newPlan.planName + ' copy'
      newPlan.id = ref.id
      newPlan.logs.created = { time: Date.now(), user: user.displayName }
      delete newPlan.logs.edited
    }

    // save plan to firestore
    const savePlan = () => {
      ref.set(newPlan).catch((error) => console.log(error))

      setCourseMap(newPlan)

      setPlans((f) => {
        const newList = [...f, ref.id]
        fs.collection('students')
          .doc(currentStudent.identifiers?.callistaPersonID)
          .update({ plans: newList })
          .catch((error) => console.log(error))
        return newList
      })

      if (!plan) selectPlan(newPlan)
      // log event to analytics
      const userProperties = {
        id: user.id,
        name: user.displayName,
        type: 'Staff',
        faculty: getFacultyFromCourseCode(newPlan.code),
      }
      analytics.setUserProperties(userProperties)
      analytics.logEvent('planCreated', {
        studentId: currentStudent.identifiers.callistaPersonID,
        studentName:
          currentStudent.personName.givenNames
            .slice(0, currentStudent.personName.givenNames.length)
            .join(' ') +
          ' ' +
          currentStudent.personName.familyName,
        planId: newPlan.id,
        staffId: user.id,
        staffName: user.displayName,
        courseCode: newPlan.code,
        courseTitle: newPlan.title,
        faculty: getFacultyFromCourseCode(newPlan.code),
      })
    }

    // assign aos labels
    if (newPlan.aos?.length && !plan) {
      unitAos({ aos: newPlan.aos.map((item) => item.code) }).then((res) => {
        res.forEach((obj) => {
          newPlan.advancedStandings.forEach((block) => {
            if (block.name === obj.unit)
              block.aos
                ? block.aos.push({ code: obj.aos, name: aosName(obj) })
                : (block.aos = [{ code: obj.aos, name: aosName(obj) }])
          })

          Object.values(newPlan.years).forEach((year) =>
            year.periods.forEach((period) =>
              period.blocks.forEach((block, i) => {
                //if not fail/discon
                if (
                  block.name === obj.unit &&
                  (block.grade
                    ? isPass(block.grade)
                    : !['DISCONTIN'].includes(block.status))
                ) {
                  block.aos
                    ? block.aos.push({ code: obj.aos, name: aosName(obj) })
                    : (block.aos = [{ code: obj.aos, name: aosName(obj) }])
                }
              })
            )
          )
        })

        savePlan()
      })
    } else {
      savePlan()
    }
  }

  // select plan

  const { redirect } = useContext(RouterState)

  const selectPlan = (item) => {
    setSelectedPlan(() => {
      sessionStorage.setItem('selectedPlan', JSON.stringify(item.id))
      redirect(
        '/browse',
        item.code
          ? {
              type: 'course',
              code: item.code,
              year: item.startingYear,
            }
          : null
      )
      return item
    })
  }

  // delete plan
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [planIdToDelete, setPlanIdToDelete] = useState(null)
  const [studentId, setStudentId] = useState(null)
  const [planIndex, setPlanIndex] = useState(null)

  const deletePlan = (id, student, index) => {
    setShowDeleteConfirmation(true)
    setPlanIdToDelete(id)
    setStudentId(student)
    setPlanIndex(index)
  }

  const confirmDelete = () => {
    setShowDeleteConfirmation(false)

    if (planIdToDelete === selectedPlan?.id || !plans.length) {
      setShowPlanningPanel(false)
      setSelectedPlan(null)
      sessionStorage.setItem('selectedPlan', JSON.stringify(null))
    }

    setPlansWithData((f) => {
      const newList = [...f]
      newList.splice(planIndex, 1)
      return newList
    })

    fs.collection('student-plans')
      .doc(planIdToDelete)
      .delete()
      .then(() => console.log(`Deleted ${planIdToDelete}`))
      .catch((error) => console.log(error))

    fs.collection('students')
      .doc(studentId)
      .update({ plans: arrayRemove(planIdToDelete) })
      .then(() => console.log(`Removed ${planIdToDelete} from ${studentId}`))
      .catch((error) => console.log(error))
  }

  // context menu
  const { setMenu } = useContext(ContextMenuData)

  const contextMenu = (e, item, i) => {
    e.preventDefault()
    setMenu({
      id: item.id,
      position: { x: e.clientX, y: e.clientY },
      items: [
        {
          name: 'Make copy',
          icon: <Icon.Copy />,
          fn: () => createNewPlan(0, item),
        },
        {
          name: 'Delete plan',
          icon: <Icon.Trash />,
          className: c.red,
          fn: () => deletePlan(item.id, item.student, i),
        },
      ],
    })
  }

  const formatDateTime = (time) => {
    if (Date.now() - 172800000 < time) {
      return formatDistanceToNow(time, { addSuffix: true })
    } else {
      let d = new Date(time)
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    }
  }

  // render plans

  const renderPlans = plansWithData.map((item, i) => {
    return (
      <div
        style={{ animationDelay: `${i * 0.2}s` }}
        className={c.plan}
        key={i}
        onClick={() => selectPlan(item)}
        onContextMenu={(e) => contextMenu(e, item, i)}>
        <div className={c.preview}>
          <Preview data={item} />
        </div>
        <div className={c.body}>
          <div className={c.content}>
            <div
              className={c.header}
              style={{ flexDirection: showPlanningPanel ? 'column' : 'row' }}>
              <h2>{item.planName}</h2>
              {item.logs.viewed && (
                <span className={c.viewed}>
                  <Icon.Eye /> Viewed {formatDateTime(item.logs.viewed)}{' '}
                </span>
              )}
            </div>
            <span>
              {item.code} - {item.title}
            </span>
            <ul className={c.aos}>
              {item.aos
                ? item.aos.map((aos, j) => {
                    const { base, contrast } = stringToColor(aos.code || aos)
                    return (
                      <li
                        key={j}
                        style={{ color: contrast, backgroundColor: base }}>
                        {aos.name || aos}
                      </li>
                    )
                  })
                : 'None selected'}
            </ul>
          </div>
          <div className={c.meta}>
            <span>
              <label>Created:</label>{' '}
              {formatDateTime(item.logs.created.time || item.logs.created)}{' '}
              {item.logs.created.user && `by ${item.logs.created.user}`}
            </span>

            {item.logs?.edited && item.id !== selectedPlan?.id && (
              <span>
                <label>Last edit:</label>{' '}
                {formatDateTime(item.logs.edited.time)} by{' '}
                {item.logs.edited.user}
              </span>
            )}
            {item.id === selectedPlan?.id && (
              <span className={c.current}>Currently selected</span>
            )}
          </div>
        </div>
      </div>
    )
  })

  //tutorial
  const { updateRef } = useContext(TutorialContext)
  const containerRef = useRef()

  useLayoutEffect(() => {
    updateRef('Information panel', containerRef.current)
  }, [updateRef])

  // render

  return (
    <div className={c.plans} ref={containerRef}>
      <LoadingScreen loaded={!loading} message={loadingMessage} type={'plan'}>
        {!blockRendering && (
          <div
            className={c.container}
            style={{
              grid: `auto-flow / repeat(${showPlanningPanel ? 1 : 2}, 1fr)`,
            }}>
            {renderPlans}
            <button
              onClick={checkEnrolments}
              className={c.createNewPlan}
              id='create-new-plan'>
              <div className={c.icon}>
                <Icon.Add />
              </div>
              <div className={c.text}>Create new plan</div>
            </button>
          </div>
        )}
      </LoadingScreen>

      {/* delete plan */}
      {showDeleteConfirmation && (
        <div className={c.selectEnrolment}>
          <h2>
            <span>Confirm deletion of this plan</span>
          </h2>

          <div className={c.buttonContainer}>
            <button onClick={confirmDelete} className={c.confirmDelete}>
              Confirm
            </button>
            <button onClick={() => setShowDeleteConfirmation(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* create plan */}
      {selectEnrolment &&
        createPortal(
          <div className={c.selectEnrolment}>
            <h2>
              <span>Select enrolment</span>
              <button onClick={() => setSelectEnrolment(false)}>Cancel</button>
            </h2>
            <p>
              <span className={c.name}>
                {currentStudent.personName.givenNames.join(' ').toLowerCase()}
              </span>{' '}
              has multiple enrolments. Please select one to create a plan from:
            </p>
            <ul className={c.enrolments}>
              {currentStudent.courseEnrolments
                .filter((item) => item.location)
                .map((item, i) => (
                  <li
                    key={i}
                    onClick={() => waitAndCreateNewPlan(i)}
                    className={c[item.status?.toLowerCase()]}>
                    <h3>
                      <span>{item.courseCode}</span>
                      <label>{item.status}</label>
                    </h3>
                    <div>{item.course?.title}</div>
                  </li>
                ))}
            </ul>
          </div>,
          document.body
        )}
    </div>
  )
}

export default Plans
