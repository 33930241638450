import React from 'react'
import c from './noteTag.module.scss'

const NoteTag = () => {
  return (
    <div className={c.noteTag}>
      <div className={c.poke} />
    </div>
  )
}

export default NoteTag
