import React, { useEffect, useState } from 'react'

export const Interface = React.createContext()

const InterfaceDataWrapper = ({ children }) => {
  const [showPlanningPanel, setShowPlanningPanel] = useState(false)
  const [offsetPlanner, setOffsetPlanner] = useState(0)

  // batch add aos
  const [unitsToAdd, setUnitsToAdd] = useState({})
  const [editMode, setEditMode] = useState(null)

  // display options
  const [showDisplayOptions, setShowDisplayOptions] = useState(false)
  const [displayOptions, setDisplayOptions] = useState(
    JSON.parse(localStorage.getItem('displayOptions')) || {
      showUnits: false,
      lockUnits: true,
      compactMode: false,
      showNotes: true,
    }
  )

  useEffect(() => {
    if (displayOptions) {
      if (displayOptions.showNotes === undefined)
        setDisplayOptions((f) => {
          return { ...f, showNotes: true }
        })
    }

    localStorage.setItem('displayOptions', JSON.stringify(displayOptions))
  }, [displayOptions])

  // render

  return (
    <Interface.Provider
      value={{
        showPlanningPanel,
        setShowPlanningPanel,
        offsetPlanner,
        setOffsetPlanner,
        unitsToAdd,
        setUnitsToAdd,
        editMode,
        setEditMode,
        showDisplayOptions,
        setShowDisplayOptions,
        displayOptions,
        setDisplayOptions,
      }}>
      {children}
    </Interface.Provider>
  )
}

export default InterfaceDataWrapper
