import React, { useEffect, useContext } from 'react'
import c from './landing.module.scss'

import StudentSearch from 'components/browser/student-search/StudentSearch'
import Illustration from 'components/utilities/icons/Illustration'
import Intro from 'components/utilities/intro/Intro'
import Modal from 'components/utilities/modal/Modal'
import { useModal } from 'components/utilities/hooks/useModal'
import { UserData } from 'components/wrappers/user-data-wrapper/UserDataWrapper'
import { fs } from 'utils/firebase'
import { formatDistanceToNow } from 'date-fns'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import Topnav from 'components/browser/topnav/Topnav'

const Landing = () => {
  const { user } = useContext(UserData)

  const { isShown, toggle } = useModal()

  useEffect(() => {
    if (user && !user.preferences?.introSeen) toggle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const closeIntro = () => {
    const userUpdate = {}
    userUpdate['preferences.introSeen'] = true
    fs.collection(user.student ? 'students' : 'staff')
      .doc(user.id)
      .update(userUpdate)

    toggle()
  }
  //open welcome intro, waiting for

  // recently viewed
  const { recentlyViewed } = useContext(UserData)
  const { findStudent } = useContext(CurrentStudent)

  const recent = recentlyViewed?.map((item, i) => (
    <li key={i} onClick={() => findStudent(item.studentNo, '/plans')}>
      <span className={c.details}>
        {item.name.toLowerCase()} - {item.studentNo}
      </span>
      <span>{formatDistanceToNow(item.time, { addSuffix: true })}</span>
    </li>
  ))

  // render

  return (
    <>
      <Topnav></Topnav>
      <div className={c.landing}>
        <Modal
          isShown={isShown}
          toggle={closeIntro}
          content={<Intro onBeginClick={closeIntro} />}></Modal>

        <Illustration.Landing />
        <StudentSearch />

        {recent?.length > 0 && (
          <div className={c.recentlyViewed}>
            <h3>Recently viewed</h3>
            <ul className={c.list}>{recent}</ul>
          </div>
        )}
      </div>
    </>
  )
}

export default Landing
