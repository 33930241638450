import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import React, { useContext, useEffect, useState } from 'react'
import c from './search-aos.module.scss'
import { makeSubstringBold } from 'utils/StringUtil'

const SearchAoS = () => {
  // AoS data
  const { eligibleAoS, autosave, setCourseMap, courseMap } = useContext(Data)
  const { setEditMode, setUnitsToAdd } = useContext(Interface)
  const [sortedAoS, setSortedAoS] = useState([])

  useEffect(() => {
    if (eligibleAoS) {
      let selected = courseMap?.aos?.map((aos) => aos.code) || []
      const list = Object.keys(eligibleAoS)
      .filter((key) => !selected.includes(key))
      .map((key) => {
        let aos = eligibleAoS[key]
        let name = `
          ${aos.type.slice(0, 2).toUpperCase()} 
          - ${aos.code} ${aos.title}`
        return { code: aos.code, name: name, start: aos.start, units: eligibleAoS[key].units }
      })

      setSortedAoS(list.sort((a, b) => b.units.length - a.units.length))
    }
  }, [eligibleAoS, courseMap])

  // search
  const [searchTerm, setSearchTerm] = useState('')
  const [suggested, setSuggested] = useState([])
  const [validAoS, setValidAoS] = useState(false)

  useEffect(() => {
    const rx = new RegExp(searchTerm.replace(/\\/g, ''), 'i')
    setSuggested(sortedAoS.filter((item, i) => rx.test(item.name)))
    if (searchTerm)
      setValidAoS(
        sortedAoS.some((item) => {
          return item?.code?.toLowerCase() === searchTerm.toLowerCase()
        })
          ? true
          : false
      )
  }, [searchTerm, sortedAoS])

  const addAoS = (selected) => {
    //e.preventDefault()
    //if (searchTerm) {
    const aos = selected.toUpperCase()
    setCourseMap((f) => {
      const newMap = { ...f }
      //const aos = searchTerm.toUpperCase()

      const units = {}

      newMap.advancedStandings.forEach((block, i) => {
        if (eligibleAoS[aos]?.units.some((item) => item === block.name)) {
          const set = block.aos
            ? new Set([...block.aos, eligibleAoS[aos]])
            : [eligibleAoS[aos]]
          block.aos = [...set]
          units[block.name] = true
        }
      })

      Object.values(newMap.years).forEach((year) =>
        year.periods.forEach((period) =>
          period.blocks.forEach((block, i) => {
            if (eligibleAoS[aos]?.units.some((item) => item === block.name)) {
              const set = block.aos
                ? new Set([...block.aos, eligibleAoS[aos]])
                : [eligibleAoS[aos]]
              block.aos = [...set]
            }
          })
        )
      )

      const list = new Set([
        ...newMap.aos,
        sortedAoS.filter((a) => a.code === aos /*searchTerm*/)[0],
      ])

      newMap.aos = [...list]

      console.log(list)

      setUnitsToAdd(units)
      setEditMode(aos)
      setSearchTerm('')
      setValidAoS(false)

      autosave(newMap)
      return newMap
    })
    //}
  }

  // render

  return (
    <div className={c.search}>
      <form className={c.input} onSubmit={(e) => e.preventDefault() /*addAoS*/}>
        <input
          autoFocus
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={"Area of study - starting year"}
        />
        {validAoS && <button>Add</button>}
      </form>
      <ul className={c.suggested}>
        {suggested.map((item, i) => (
          <li
            key={i}
            onClick={(e) => addAoS(item.code) /*setSearchTerm(item.code)*/}
          >
            <span className={c.name}>
              {makeSubstringBold(searchTerm, item.name)} -{" "}
              <span className={c.secondary}>{item.start}</span>
            </span>
            <span className={c.number}>
              <span className={c.secondary}>Eligible units: </span>
              {item.units.length}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SearchAoS
